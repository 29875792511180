import EmptyImage from '@assets/default-image.svg';
import { Card, Image, ListContainer, Paragraph, StatusIndicator } from '@components';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { BASENAME, RouterParams, ROUTES } from 'Router';
import { ItemDetails, ListItemDetails, StyledListItem } from './ProductVariantInformation.styles';

export interface ProductVariantInformationProps {
  itemDetails: ItemDetailsResponse;
}

export const ProductVariantInformation = ({
  itemDetails,
}: ProductVariantInformationProps): ReactElement => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { country } = useParams<RouterParams>();

  const onClickProduct = (productId: string) => (): void => {
    navigate(`/${BASENAME}${ROUTES.PRODUCT_DETAILS}/${productId}/${country}`);
  };

  const onClickVariant = (productId: string, variantId: string) => (): void => {
    navigate(
      `/${BASENAME}${ROUTES.PRODUCT_DETAILS}/${productId}${ROUTES.VARIANT_DETAILS}/${variantId}/${country}`
    );
  };

  const hasProduct = itemDetails?.product?.id;
  const hasVariant = hasProduct && itemDetails?.variant?.gtin;

  const imageContainerCss = {
    padding: 0,
    width: 64,
    height: 64,
    minWidth: 64,
    minHeight: 64,
    '& img': {
      aspectRatio: 1,
    },
  };

  return (
    <Card
      id="item-details-page-product-variant-information"
      title={formatMessage({ id: 'ItemDetailsPage.PRODUCT_VARIANT_INFORMATION' })}
      border="medium"
      elevated="small"
    >
      <ListContainer id="item-details">
        <StyledListItem
          id="items-details-page__product-information"
          variant="navigation"
          mb={'4'}
          onClick={onClickProduct(itemDetails?.product?.id)}
          disabled={!hasProduct}
        >
          <Image
            id="items-details-page__product-image"
            variant="fit"
            width="100%"
            height="100%"
            containerCss={imageContainerCss}
            src={itemDetails?.product?.image || EmptyImage}
          />
          <ListItemDetails>
            <ItemDetails data-testid="items-details-page__product-information__product">
              <Paragraph
                text={formatMessage({ id: 'ItemDetailsPage.PRODUCT_LABEL' })}
                weight="semibold"
              />
              <Paragraph text={itemDetails?.product?.name} weight="normal" />
            </ItemDetails>
            <ItemDetails data-testid="items-details-page__product-information__variants">
              <Paragraph
                text={formatMessage({ id: 'ItemDetailsPage.COUNT_VARIANTS_LABEL' })}
                weight="normal"
                size="small"
              />
              <Paragraph
                text={itemDetails?.product?.variantAssociated?.toString()}
                size="small"
                weight="normal"
              />
            </ItemDetails>
            <StatusIndicator
              id="items-details-page__product__status-indicator"
              text={
                itemDetails?.product?.status &&
                formatMessage({ id: `Default.${itemDetails?.product?.status}` })
              }
              status={itemDetails?.product?.status}
            />
          </ListItemDetails>
        </StyledListItem>
        <StyledListItem
          id="items-details-page__variant-information"
          variant="navigation"
          onClick={onClickVariant(itemDetails?.product?.id, itemDetails?.variant?.gtin)}
          disabled={!hasVariant}
        >
          <Image
            id="items-details-page__variant-image"
            variant="fit"
            width="100%"
            height="100%"
            containerCss={imageContainerCss}
            src={itemDetails?.variant?.image || EmptyImage}
          />
          <ListItemDetails>
            <ItemDetails data-testid="items-details-page__variant-information__variant">
              <Paragraph
                text={formatMessage({ id: 'ItemDetailsPage.VARIANT_LABEL' })}
                weight="semibold"
              />
              <Paragraph text={itemDetails?.variant?.name} weight="normal" />
            </ItemDetails>
            <ItemDetails data-testid="items-details-page__variant-information__gtin">
              <Paragraph
                text={formatMessage({ id: 'ItemDetailsPage.GTIN_LABEL' })}
                weight="normal"
                size="small"
              />
              <Paragraph text={itemDetails?.variant?.gtin} size="small" weight="normal" />
            </ItemDetails>
            <StatusIndicator
              id="items-details-page__variant__status-indicator"
              text={
                itemDetails?.variant?.status &&
                formatMessage({ id: `Default.${itemDetails?.variant?.status}` })
              }
              status={itemDetails?.variant?.status}
            />
          </ListItemDetails>
        </StyledListItem>
      </ListContainer>
    </Card>
  );
};
