import { Input } from '@components';
import React from 'react';
import { useIntl } from 'react-intl';
import { GenericInputProps } from '../../GenericAttributes.types';

const TextInput = ({
  attribute,
  isEditing,
  onChange,
  value,
  errorMessage,
  hasError,
  name,
  eventHandler,
}: GenericInputProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const handleChange = (e: React.KeyboardEvent<HTMLInputElement>) => {
    return onChange(e.currentTarget.value);
  };

  return (
    <Input
      onChange={handleChange}
      value={value as string}
      label={attribute.name}
      id={attribute.attributeId}
      required={!!attribute.isRequired}
      viewMode={!isEditing}
      errorText={errorMessage}
      hasError={hasError}
      width={'100%'}
      onKeyDown={eventHandler}
      tooltipText={attribute.description}
      placeholder=""
      optionalText={formatMessage({ id: 'Components.OPTIONAL' })}
      name={name}
    />
  );
};

export { TextInput };
